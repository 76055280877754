$main-color: #FAC043;
$main-color-gentle: rgba(255,196,69,0.8);
$main-color-supergentle: rgba(255,196,69,0.2);
$main-color-dark: #FABA43;
$secondary-light: #9E89FF;
$secondary-dark: #381DAD;
$tertiary-light:#50E5FA;
$tertiary-dark:#40A0AD;
$gray-light: #ddd;
$main-soft: #555;
$off-white: #F5F5F5;
$admin-color: #6ece78;

$off-green: #e3f9e0;
$off-yellow: #ffeed1; 
$off-red:  #FFCDC4; 

$contrast-green: #72c166;
$contrast-yellow: #f99b00;
$contrast-red: #C2665D;

$background: #f2efed3d;

$border-radius: 3px;

$main-font: "Montserrat", -apple-system, sans-serif;


.admin_bar {
    display: flex;
    background-color: $admin-color;
    color: #fff;
    
    width: 200px;
    position: fixed;
    left: 0px;
    top: 0px;
    padding: 20px;
    border-radius: 3px;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    z-index: 9999;

    .bottom {
        position: relative;
        margin-bottom: 50px;

        .download {
            text-align: center;
            cursor: pointer;
        }

        .tst {
            padding: 10px;
            height: 100px;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;

            &:hover {
                background-color: rgba(255,255,255,0.4);
            }

            &.error { 
                background-color: rgba(255,0,0,0.4);
            }
        }

    }

    .top {
        position: relative;
    }

    .close {
        position: absolute;
        right: 10px;
        top: 10px;
        font-size: 12px;
        color: #fff;
        cursor: pointer;
    }

    .section {
        flex-direction: column;
        display: flex;
        margin-bottom: 20px;
        .sort_label {
            margin-bottom: 10px;;
        }

        .price_override_input {
            box-sizing: border-box;
            border-color: hsl(0, 0%, 100%);
            border-radius: 4px;
            border-width: 1px;
            min-height: 38px;
            width: 200px;
            color: #444;
        }

        &.stats {
            .title {
                font-weight: bold;
                margin-bottom: 3px;
            }

            .row {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 5px;

                .label {
                    font-size: 12px;
                }
            }
        }
    }


}

body {
    font-family: $main-font;
}

@keyframes pulse {
    from {
        transform: scale(1.0);
        color: $main-soft;
    }
    to {
        transform: scale(1.3);
        color: red;
    }
}

.collapse {
    @media only screen and (max-width: 600px) {
        display: none;
    }
    @media only screen and (min-width: 600px) {
        display: block;
    }
}

.thanks {
    display: flex;
    align-self: stretch;
    padding: 20px;
    justify-content: center;
    align-items: center;
    font-size: 30px;
    font-weight: bold;
    color: $main-color;
    background-color: #fff;
}


.item {
    background-color: #fff;
    border-radius: $border-radius;
    border: solid 1px $gray-light;
    padding: 10px;
    &:hover { 
        border: solid 1px $secondary-light;
    }

    &.highlight {
        background-color: $off-green;
    }

    .img-container {
        width: 180px;
        overflow: hidden;
        border-radius: $border-radius;

        &.table {
            width: 100px;
        }

        @media only screen and (max-width: 962px) {
            width: 80px;
            &.table {
                width: 100px;
            }
        }

        @media only screen and (max-width: 1280px) and (min-width: 963px) {
            width: 120px;
            &.table { 
                width: 100px;
            }

        }

        &.basket {
            width: 120px;
        }

        .product-img {
            width: 180px;
            height: 180px;

            &.table {
                width: 100px;
                height: 100px;
            }

            @media only screen and (max-width: 962px) {
                width: 80px;
                height: 80px;

                &.table {
                    width: 100px;
                    height: 100px;
                }
            }

            @media only screen and (max-width: 1280px) and (min-width: 963px) {
                width: 120px;
                height: 120px;
                &.table {
                    width: 100px;
                    height: 100px;
                }
            }

            &.basket {
                width: 120px;
                height: 120px;
            }
        }
    }

    @media only screen and (min-width: 768px) {
        .title {
            font-size: 16px;
            display: block;
            font-weight: 600;
        }
    }
    @media only screen and (max-width: 400px) {
        .title {
            font-size: 11px;
            display: block;
            font-weight: 600;
        }
    }
    @media only screen and (max-width: 768px) {
        .title {
            font-size: 14px;
            display: block;
            font-weight: 600;
        }
    }

    .prom-triangle {
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 40px 40px 0 0;
        border-color: #ff0000 transparent transparent transparent;
        position: absolute;
        top: 0;
        left: 0;
    }

    .prom-box {
        position: absolute;
        top: 0;
        left:0;
        right: 0;
        height: 30px;
        color: #fff;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        background-color: red;
        font-weight: bold;
    }


    &.basket {
        display: flex;
        align-self: stretch;
        height: 120px;
        border-radius: $border-radius;
        margin: 10px;
        position: relative;
        width: 300px;

        .unavailable {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            z-index: 300;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color:rgba(255,0,0,0.75);
            color: #fff;
            font-weight: bold;
            font-size: 20px;
            text-align: center;
            flex-direction: column;
            .notify_text {
                font-size: 14px;
                font-weight: 400;
                margin-top: 10px;
            }
        }

        .detail {
            padding: 5px;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-self: stretch;
            position: relative;
            flex: 1;
            
            .top {
                
            }

            .mid {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                color: red;
                font-size: 12px;
            }
            .btm {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                position: absolute;
                bottom: 0;
                right: 0;
                left: 0;

                .case_qty {
                    flex-direction: row;
                    display: flex;
                    align-items: center;
                    width: 120px;
                    justify-content: space-evenly;
                }

                .price {
                    font-size: 18px;
                    font-weight: bold;

                    @media only screen and (max-width: 600px) {
                        font-size: 14px;
                    }

                    &.prom {
                        color: red;
                    }

                    &.compare {
                        display: inline;
                        font-size: 14px;
                        text-decoration: line-through;
                        color: #444;
                        @media only screen and (max-width: 600px) {
                            font-size: 12px;
                        }
                    }


                }
            }
        }
    }

    &.table_product {
        height: 104px;
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 4px;
        margin-left: 4px;
        margin-right: 4px;
    }

    &.table_product {

        
        &.loading {
            background-color: $gray-light;
        }

        .case_qty {
            width: 120px;
            display:flex;
            flex-direction: row;
            justify-content: space-evenly;
        }

        .title {
            font-size: 16px;
        }
        
        .price_and_cart {
            align-self: stretch;
            display: flex;
            flex: 1;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            font-size: 16px;

            .unit {
                font-size: 16px;
                color: #666;

                @media only screen and (max-width: 1280px) {
                    font-size: 14px;
                }

                &.prom {
                    color: red;

                }

                &.compare {
                    color: #444;
                    text-decoration: line-through;
                    font-size: 14px;
                    @media only screen and (max-width: 1280px) {
                        font-size: 12px;
                    }
                }
            }
            .case {
                font-size: 18px;
                font-weight: bold;
                display: block;
                @media only screen and (max-width: 1280px) {
                    font-size: 14px;
                    font-weight: bold;
                }

                &.prom {
                    color: red;
                }

                &.compare {
                    color: #444;
                    text-decoration: line-through;
                    display: inline;
                    font-size: 14px;
                    @media only screen and (max-width: 600px) {
                        font-size: 13px;
                    }
                }
            }

        }

    }

    &.product {
        width: 200px;
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;

        &.wigig {
            background-color: #e6ffff;
        }

        &.loading {
            min-height: 368.5px;
            background-color: $gray-light;
        }

        .heart_holder {
            position: absolute;
            top: 10px;
            right: 10px;
            cursor: pointer;

            border-radius: 20px;
            background-color: rgba(255,255,255,0.75);
            padding: 5px;
        }

        .ean {
            font-size: 10px;
            margin-top: 4px;
            margin-bottom: 3px;
        }

        @media only screen and (min-width: 600px) and (max-width: 1280px) {
            width: 150px;
        }

        .other_basket {
            position: absolute;
            top: 10px;
            right: 10px;
            
            &.visible {
                animation: pulse 1s infinite alternate;
            }

            &.invisible {
                display: none;
            }

        }

        .global_basket_count {
            position: absolute;
            top: 10px;
            right: 10px;
            font-size: 16px;
            font-weight: bold;
            display: flex;
            flex-direction: row;
            align-items: center;
            span {
                margin-left: 3px;
                position: relative;
                top: 2px;
            }
        }

        .admin_menu {
            position: absolute;
            cursor: pointer;
            top: 10px;
            left: 10px;
        }

        .detail {
            align-self: stretch;
            margin-top: 5px;
            margin-bottom: 5px;
            height: 80px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            padding-bottom: 5px;        
            
            @media only screen and (max-width: 1280px) {
                height: 60px;
            }
        }

        .categories {
            font-size: 11px;
            margin-bottom: 5px;
            .category {
                &:hover {
                    color: $main-color;
                    cursor: pointer
                }
            }
        }

        .title {
            font-size: 16px;
            display: block;
            font-weight: 600;
            height: 56px;
    
            @media only screen and (max-width: 1280px) {
                font-size: 12px;
                height: 46px;
            }
        }

        .case-size {
            font-size: 12px;
            @media only screen and (max-width: 600px) {
                font-size: 12px;
            }
        }

        .case_qty {
            flex-direction: row;
            display: flex;
            align-items: center;
            width: 100px;
            justify-content: space-evenly;

            &.table {
                width: 200px;
            }

            @media only screen and (max-width: 600px) {
                font-size: 12px;
                width: 80px;

                &.table {
                    width: 160px;
                }
            }
        }

        .price_and_cart {
            align-self: stretch;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            margin-top: 10px;
            align-items: center;
            font-size: 14px;

            .unit {
                font-size: 12px;
                color: #666;

                @media only screen and (max-width: 1280px) {
                    font-size: 11px;
                }

                &.prom {
                    color: red;

                }

                &.compare {
                    color: #444;
                    text-decoration: line-through;
                    font-size: 11px;
                    @media only screen and (max-width: 1280px) {
                        font-size: 10px;
                    }
                }
            }
            .case {
                font-size: 14px;
                font-weight: bold;
                display: block;
                @media only screen and (max-width: 1280px) {
                    font-size: 10px;
                    font-weight: bold;
                }

                &.prom {
                    color: red;
                }

                &.compare {
                    color: #444;
                    text-decoration: line-through;
                    display: inline;
                    font-size: 12px;
                    @media only screen and (max-width: 600px) {
                        font-size: 10px;
                    }
                }
            }

        }

        .actions {
            display: flex;
            align-self: stretch;
            flex-direction: row;
            

            svg {
                width: 20px;
                height: 20px;
                @media only screen and (max-width: 962px) {
                    height: 16px;
                    width: 16px;
                }
            }

            .icon {
                cursor: pointer;
            }
            .row {
                display: flex;
                height: 24px;
                flex-direction: row;
                align-items: center;
                align-self: stretch;
                

                span {
                    margin-left: 5px;
                    color: #666;
                    font-size: 11px;

                    @media only screen and (max-width: 962px) {
                        font-size: 9px;
                    }
                }
            }
        }
    }

    &.order {
        display: flex;
        align-self: stretch;
        margin: 10px;
        flex-direction: column;
        position: relative;

        .view_all {
            color: $main-color;
            cursor: pointer;
            position: absolute;
            bottom: 10px;
            right: 10px;
        }

        .body {
            display: flex;
            flex-direction: row;
            height: 140px;
            
            .image_set {
                width: 120px;
                height: 120px;
                display: grid;
                gap: 0px 0px;

                &.one {
                    grid-template-columns: repeat(1, 120px);
                }
                &.four {
                    grid-template-columns: repeat(2, 60px);
                }
                &.nine {
                    grid-template-columns: repeat(3, 40px);
                }
                &.sixteen {
                    grid-template-columns: repeat(4, 30px);
                }

                .tile {
                    object-fit: contain;
                    width: 100%;
                }
            }

            .detail {
                flex: 1;
                align-self: stretch;
                display: flex;
                flex-direction: column;
                padding: 10px;
                margin-bottom: 10px;

                .datapoint {
                    font-size: 14px;
                    color: $main-soft;
                    margin-bottom: 3px;
                    margin-top: 5px;
                }

                .row {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: flex-start;

                    .column {
                        display: flex;
                        flex-direction: column;
                        width: 30%;
                        height: 100%;
                        
                        &.collapse {
                            @media only screen and (max-width: 600px) {
                                display: none;
                            }
                            @media only screen and (min-width: 600px) {
                                display: block;
                            }
                        }
                    }
                }
            }
        }

        .products {
            max-height: 300px;
            overflow-y: auto;
            display: flex;
            flex-direction: column;

            .product {
                display: flex;
                flex-direction: row;
                align-self: stretch;
                justify-content: space-evenly;
                font-size: 11px;
                color: #555;

                .column {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    text-align: center;
                    
                    &.header {
                        font-weight: bold;
                        font-size: 14px;
                    }
                }
            }
        }
    }
}

.button {
    cursor: pointer;
    background-color: $main-color;
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 5px 10px 5px 10px;
    font-weight: bold;
    color: #fff;
    height: 20px;


    &.wdth {
        width: 100px;
    }

    &:hover {
        background-color: #E39032;
    }

    &.fill {
        flex: 1
    }

    &.secondary {
        background-color: $secondary-light;
    }

    &.invert {
        border: solid 1px $main-color;
        background-color: rgba(0,0,0,0);
        color: $main-color;
        background-color: #fff;
    }

    &.margin {
        margin: 10px;
    }

    &.search {
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
    }

    &.search {
        &.clear {
            position: relative;
            left: -2px;
        }
    }
}

.logo {
    width: 100px;
}

.main_menu {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    position: sticky;
    top: 0;
    background-color: #fff;
    z-index: 999;
    align-self: stretch;

    .admin {
        background-color: $admin-color;
        color: #fff;

    }

    .left {
        display: flex;
        height: 60px;
        flex-direction: row;
        @media only screen and (max-width: 962px) {
            height: 40px;
        }
    }

    .right {
        height: 60px;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding-left: 10px;
        padding-right: 10px;
        @media only screen and (max-width: 962px) {
            height: 40px;
        }
    }

    .search_bar {
        display: flex;
        flex-direction: row;
    }
}

.menu-item {
    padding-left: 10px;
    padding-right: 10px;
    align-self: stretch;
    justify-content: center;
    align-items: center;
    display: flex;
    font-weight: bold;
    color: $main-soft;
    cursor: pointer;
    height: 60px;

    @media only screen and (max-width: 962px) {
        height: 40px;
        padding-left: 5px;
        padding-right: 5px;
    }

    &.selected {
        background-color: rgba(210, 210, 210, 0.5);
    }

    &:hover {
        color: #333;
        background-color: rgba(200, 200, 200, 0.5);
    }
}

a.clear {
    text-decoration: none;
}

.global {
    display: flex;
    flex: 1;
    width: 99vw;
    align-items: center;
    flex-direction: column;
}

.container {
    width: 100%;
    
    min-height: 40vh;
    align-items: center;
    max-width: 1280px;
    display: flex;
    flex-direction: column;
    background-color: $background;
    align-items: center;
    border-left: solid 1px #ddd;
    border-right: solid 1px #ddd;


    &.footer {
        background-color: $main-color;
    }

    &.white {
        background-color: #fff;
    }

    &.contact {
        .row {
            display: flex;
            padding: 20px;
            flex-direction: row;
            flex-wrap: wrap;
            align-self: stretch;
            .col {
                flex: 1;
                background-color: #fff;
                flex-direction: column;
                align-items: center;
                display: flex;
            }
        }
    }
}

.grid {
    width: 100%;
    display: grid;
    
    &.basket {
        grid-template-columns: repeat(auto-fill, minmax(330px, 1fr));
        gap: 10px 10px;
        justify-items: center;
    }

    &.product {
        justify-items: center;
        @media only screen and (max-width: 1280px) {
            grid-template-columns: repeat(auto-fill, minmax(170px, 1fr));
            gap: 10px 5px;
        }
        @media only screen and (min-width: 1280px) {
            grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
            gap: 20px 5px;
        }
    }
}

@media only screen and (max-width: 850px) {
    .shop-filters {
        top: 60px;
    }
}

@media only screen and (min-width: 850px) {
    .shop-filters {
        top: 60px;
    }
}

.shop-filters {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: stretch;
    margin-bottom: 2px;
    justify-content: space-between;
    position: sticky;
    z-index: 500;
    background-color: #fff;
    border-bottom: solid 1px #ddd;
    color: #666;


    .row {
        display: flex;
        align-self: stretch;
        flex-direction: row;
        justify-content: center;
        flex-wrap: wrap;

        @media only screen and (max-width: 550px) {
            flex-direction: column;
            align-items: center;
        }

        &.two {
            justify-content: space-between;
        }

        &.border {
            border-bottom: solid 1px $off-white;
        }

        .left {
            display: flex;
            flex-direction: row;
        }
    }

    .sml_section {
        display:flex;
        flex-direction: row;
        align-items: center;
        margin-right: 10px;
        margin-top: 5px;
        margin-bottom: 5px;
        margin-left: 10px;
    }

    .section {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-items: center;
        padding-top: 5px;
        padding-bottom: 5px;
        padding-left: 10px;
        padding-right: 10px;
        height: 40px;

        &.dropdown {
            width: 285px;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            .sort_label {
                width: 60px;
                font-size: 12px;
            }

            @media only screen and (min-width: 700px) and (max-width: 700px) {
                width: 100px;
            }
        }

        &.large {
            width: 380px;
        }

        &.small {
            width: 250px;
        }

        .sort_label {
            margin-right: 10px;
        }

        input {
            border: none;
            font-size: 18px;
            color: #333;
            height: 30px;
            background-color: $off-white;
            width: 150px;
            box-sizing: border-box;

            &:focus {
                border-radius: 0;
                background-color: #fff;
                border: solid 1px black;
            }
        }

        .button {
            height: 30px;
        }

        &.page_buttons {
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            .page_text {
                position: relative;
                bottom: 3px;
                margin: 10px;
                font-weight: bold;
            }

            .before_pg { 
                margin: 5px;
                margin-top: 10px;
                margin-bottom: 10px;
                position: relative;
                bottom: 2px;
                font-size: 14px;
                cursor: pointer;
            }

            .after_pg {
                margin: 5px;
                margin-top: 10px;
                margin-bottom: 10px;
                position: relative;
                bottom: 2px;
                font-size: 14px;
                cursor: pointer;
            }
        }
    }
}

.list_footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 10px;
    background-color: #fff;
    align-self: stretch;
}

.order_box {
    display: flex;
    align-self: stretch;
    position: sticky;
    top: 60px;
    background-color: #6ece78;
    padding: 10px;
    z-index: 500;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    @media only screen and (max-width: 600px) {
        top: 60px;
    }

    .header {
        font-size: 22px;
        color: #fff;
        font-weight: bold;
        @media only screen and (max-width: 600px) {
            font-size: 16px;
        }
    }

    .datapoint {
        font-size: 18px;
        font-weight: bold;
        color: #fff;
        @media only screen and (max-width: 600px) {
            font-size: 12px;
            font-weight: 400;
        }
    }
}

.home_about_box {
    display: flex;
    flex-direction: row;
    height: 300px;
    background-color: $off-white;
    
    .image {
        flex: 1;
        display: flex;
        align-self: stretch;
        justify-content: center;
        align-items: center;

        img {
            height: 100%;
            width: 100%;
            object-fit: cover;
        }
    }

    .detail {
        flex: 1;
        align-self: stretch;
        padding: 30px;
        display: flex;
        flex-direction: column;

        .text {
            font-size: 22px;
        }

        .header {
            font-size: 30px;
            font-weight: bold;
            display: block;
            margin-bottom: 10px;
            flex: 1;
        }
    }

    .row {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-self: stretch;
    }

    
}

.product_row_holder {
    background-color: $off-white;
}

.marquee_brand_img {
    height: 100px;
    margin-left: 20px;
    z-index: 999;
}

.marquee {
    height: 200px;
    align-self: stretch;
    max-width: 1280px;
}

.stock_indicator {
    border-radius: 20px;
    padding: 3px 10px 2px;
    font-weight: 700;
    font-size: 0.7em;
    text-align: center;

    @media only screen and (max-width: 962px) {
        padding: 2px;
        padding: 2px 5px 2px;
        font-weight: 500;
        font-size: 0.6em;
    }

    &.ok {
        background-color: $off-green;
        color: $contrast-green
    }

    &.low {
        background-color: $off-yellow;
        color: $contrast-yellow;
    }

    &.nostock {
        background-color: $off-red;
        color: $contrast-red
    }
}

.signup {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 90%;
    background-color: #fff;
    padding: 20px;
    border-radius: $border-radius;
    margin: 20px;

    &.settings {
        .row {
            display: flex;
            flex-direction: row;
            align-self: stretch;
            justify-content: space-evenly;
            .col {
                padding: 20px;

                .success {
                    font-size: 12px;
                    max-width: 200px;
                    color: green;
                    font-weight: bold;
                }

                .failure {
                    font-size: 12px;
                    max-width: 200px;
                    color: red;
                    font-weight: bold;
                }
                &:not(:last-child) {
                    border-right: solid 2px grey;
                }
            }
        }
    }
}

form.reset_pass {
    display: flex;
    flex-direction: column;
    
    label {
        font-size: 12px;
        margin-bottom: 2px;
    }
    input {
        width: 200px
    }
}

form.notify {
    display: flex;
    flex-direction: row;
    width: 220px;
    font-size: 12px;
}

div {
    &.signup {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 90%;
        background-color: #fff;
        padding: 20px;
        border-radius: $border-radius;
        margin: 20px;

        &.contact {
            padding: 0px;
            margin: 0px;
            flex: 1;
            width: auto;
        }
    }
}

form {
    &.signup {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 90%;
        background-color: #fff;
        padding: 20px;
        border-radius: $border-radius;
        margin: 20px;

        &.contact {
            padding: 0px;
            margin: 0px;
            flex: 1;
            width: auto;
        }
    }

    textarea, input {
        margin-right: 5px;
        border: none;
        border-radius: $border-radius;
        font-size: 18px;
        color: #333;
        padding: 3px;
        height: 30px;
        border: solid 1px $gray-light;
        max-width: 350px;
        width: 50%;
    }

    
    .form_select {
        width: 50%;
        padding: 3px;
        max-width: 350px;
        height: 30px;
        margin-right: 5px;
        border-radius: $border-radius;
        font-size: 18px;
        color: #333;
        border: solid 1px $gray-light;
        
    }

    textarea {
        height: 80px;
    }

    

    label {
        color: $main-soft;
        margin: 10px;

        &.required {
            font-weight: bold;
        }
    }

    .top_message {
        margin-bottom: 20px;
    }

    .error {
        font-size: 13px;
        margin-top: 5px;
        color: red;
    }
}

a.inline {
    display: inline;
}

.carousel_prom {
    width: 100%;
    height: 582px;

    &.small {
        height: 116px;
    }
}

.search_input {
    border: solid 2px $secondary-light;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}

.right_prom {
    display: none;
}

@media only screen and (min-width: 1700px) {
    .on_scroll_admin {
        display: block;
        width: 200px;
        padding: 10px;
        background-color: rgba(255,0,0,0.5);
        position: fixed;
        top: 150px;
        left: 100px;
    }
}

@media only screen and (min-width: 1700px) {
    .right_prom {
        display: block;
        width: 200px;
        height: 70vh;
        background-color: red;
        position: fixed;
        top: 150px;
        right: 100px;
    }
}

.prom_banner {
    display: flex;
    align-self: stretch;
    padding: 10px;
    background-color: $main-color;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    color: #fff;
    font-weight: bold;
}

ul {
    list-style-type: none;

    .heading {
        font-weight: bold;
        margin-bottom: 10px;
        font-size: 20px;
    }

    li {
        margin-bottom: 5px;
    }
}

.footer {
    width: 100%;
    border-left: solid 1px #ddd;
    border-right: solid 1px #ddd;
    background-color: $main-color;
}

.catalogue_dropzone {
    border: dashed 2px white;
    position: relative;

    
    &.center {
        display: flex;
        flex-direction: row;
        justify-content: center;
    }

    .uploading {
        font-size: 12px;
    }

    input {
        display: none;
    }
}


.about_box {
    margin: 20px;
    background-color: #fff;
    padding: 10px;

    .button_row {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
    }
}

.oos_modal_row {
    .checkbox {
        margin-left: 10px;
    }
}

.language {
    position: absolute;
    top: 5px;
    left: 5px;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;

    span {
        font-size: 12px;
        margin-left: 3px;
    }
}


.rc-footer-column {
    margin-bottom: 20px !important;
}

.rc-footer-container {
    padding: 20px 0 20px !important;
}

.as_customer {
    position: fixed;
    bottom: 20px;
    right: 20px;
    border-radius: 4px;
    background-color: #6ece78;
    padding: 10px;
    color:#fff;
    font-weight: bold;
    z-index: 999;
}

.carousel_prom_small {
    width: 100%;
    object-fit: contain;
}


.carousel_prom {
    width: 100%;
    object-fit: contain;
}


.tags_box {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    align-self: stretch;
    align-items: center;

    .title {
        font-weight: bold;
        color: #555;
        margin: 10px;
    }

    .tags_holder {
        display: flex;
        flex-direction: row;
        padding: 5px;
    }

    .input_row {
        display: flex;
        flex-direction: row;
        width: 50%;
        input {
            padding: 5px;
            height: 20px;
            flex: 1;
        }

        .button {
            height: 30px;
        }
    }

    .tag_bit {
        border-radius: 2px;
        display: flex;
        flex-direction: row;
        margin: 2px;
        min-width: 0px;
        box-sizing: border-box;
        background-color: rgba(0, 184, 217, 0.1);

        .value {
            border-radius: 2px;
            color: rgb(0,184,217);
            font-size: 85%;
            overflow: hidden;
            padding: 3px 3px 3px 6px;
            text-overflow: ellipsis;
            white-space: nowrap;
            box-sizing: border-box;
        }
        
        .delete {
            -mox-box-align: center;
            align-items: center;
            border-radius: 2px;
            display: flex;
            padding-left: 4px;
            padding-right: 4px;
            box-sizing: border-box;
            color: rgb(0, 184, 217);
            cursor: pointer;
        }

    }
}

.amendement_modal {
    .row {
        display: flex;
        flex-direction: row;
        .col {
            margin-right: 20px;
            width: 100px;
            .pic {
                width: 100px;
                height: 100px;
            }

            .title {
                font-weight: bold;
            }

        &.ascol {
            width: 200px;
            display: flex;
            flex-direction: column;
            margin-right: 10px;
        }
        }

    }

    .button_row {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-self: stretch;
    }
}

.top_menu {
    padding-left: 5px;
    flex-direction: row;
    display: flex;
    background-color: rgb(252,251,251);
    align-self: stretch;
    padding-top: 5px;
    padding-bottom: 5px;
    position: sticky;
    top: 60px;
    z-index: 7000;

    @media only screen and (max-width: 962px) {
        top: 40px;
    }


    .filters {
        flex-direction: row;
        display: flex;
        flex: 1;
        flex-wrap: wrap;
    }

    .paginator {
        margin-right: 10px;
        display: flex;
        flex-direction: row;
        align-items: center;
        align-self: stretch;
        justify-content: flex-end;

        .page_buttons {
            align-items: center;
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            .page_text {
                position: relative;
                bottom: 3px;
                margin: 10px;
                font-weight: bold;
            }

            .before_pg { 
                margin: 5px;
                margin-top: 10px;
                margin-bottom: 10px;
                position: relative;
                bottom: 2px;
                font-size: 14px;
                cursor: pointer;
            }

            .after_pg {
                margin: 5px;
                margin-top: 10px;
                margin-bottom: 10px;
                position: relative;
                bottom: 2px;
                font-size: 14px;
                cursor: pointer;
            }
        }
    }

    .clear_all {
        background-color: $main-color-gentle;
        margin-right: 5px;
        margin-bottom: 5px;
        height: 40px;
        border-radius: 5px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        position: relative;
        cursor: pointer;
        padding-left: 3px;
        padding-right: 3px;
        box-shadow: 0 2px 4px 0 rgba(0,0,0,0.25);
    }


    .filter {
        background-color: #fff;
        margin-right: 5px;
        margin-bottom: 5px;
        height: 40px;
        width: 165px;
        border-radius: 3px;
        box-shadow: 0 2px 4px 0 rgba(0,0,0,0.25);
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        position: relative;
        z-index: 7000;
        cursor: pointer;

        &.dirty {
            background-color: $main-color-gentle;
        }

        &.priority {
            z-index: 7060;
        }

        .options {
            overflow-y: auto;
            max-height: 280px;

            .option-group {
                .title {
                    margin-bottom: 5px;
                    font-weight: bold;
                }
            }

            ul {
                padding: 0;
                margin: 0;

                li {
                    cursor: pointer;
                    margin-bottom: 5px;
                    padding: 5px;

                    label {
                        cursor: pointer;
                        &.selected {
                            color: $main-color;
                            font-weight: bold;
                        }
                    }

                    &:hover {
                        color: $main-color;
                    }
                }
            }
        }

        &.closed {

        }

        .expanded {
            position: absolute;
            left:0;
            right:0;
            top: 36px;
            max-height: 300px;
            background-color: #fff;

            border-bottom-left-radius: 5px;
            border-bottom-right-radius: 5px;
            box-shadow: 0 4px 4px 0 rgba(0,0,0,0.25);
            padding: 10px;

            .clear {
                display: flex;
                align-self: stretch;
                justify-content: center;
                align-items: center;
                padding: 10px;
                cursor: pointer;
                font-size: 12px;
                color: #444;
                text-align-last: center;
            }
        }
    }

    .filter_section {
        z-index: 7000;
        width: 250px;
        height: 50px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        position: relative;
        overflow: visible;
        text-align: center;
        color: #555;
        border-collapse: collapse;
        background-color: #fff;
        box-shadow: 0 2px 4px 0 rgba(0,0,0,0.25);
        margin-right: 4px;
        border-radius: 5px;
        border: 1px solid #cdcdcd;
        font-size: 0.875rem;

        &.expanded {
            height: 200px;
        }

        &.priority {
            z-index: 7060;
        }

        @media only screen and (max-width: 962px) {
            width: 120px;
            height: 30px;
        }

        @media only screen and (max-width: 1280px) and (min-width: 963px){
            width: 150px;
            height: 40px;
        }

        .value {
            font-size: 0.875rem;
            @media only screen and (max-width: 1280px) {
                font-size: 0.875rem;
            }
        }

        &:not(:last-child) {
            border-right: solid 2px #fff;
        }
        

        cursor: pointer;
        &.blank {
            .label {
                font-size: 16px;
                color:#555;
                @media only screen and (max-width: 1280px) {
                    font-size: 14px;
                }
            }
        }

        &.not-blank {
            .label {
                font-size: 11px;
                position: absolute;
                top: 2px;
                left: 5px;
                @media only screen and (max-width: 1280px) {
                    font-size: 10px;
                }
            }
        }

        .label-container {
            display: flex;
            flex-direction: row;
            align-items: center;
        }

        .dropdown {
            z-index: 9999;
            width: 160px;
            background-color: #fff;
            cursor: pointer;
            max-height: 500px;
            overflow-y: auto;
            overflow-x: hidden;
            border-radius: 5px;
            position: absolute;
            top: 50px;
            
            @media only screen and (max-width: 1280px) {
                top: 42px;
            }

            &.hidden {
                display: none;
            }
            &.showing {
                display: block;
            }

            ul {
                padding: 0px;
                z-index: 7050;
                li {
                    display: flex;
                    align-self: stretch;
                    justify-content: center;
                    align-items: center;
                    height: 48px;
                    width: 100%;
                    text-align: center;
                    cursor: pointer;

                    @media only screen and (max-width: 1280px) {
                        height: 40px;
                        font-size: 12px;
                    }

                    &:not(:last-child) {
                        border-bottom: solid 1px #ccc;
                        margin-bottom: 2px;
                    }

                    &.group {
                        cursor: auto;
                        padding-top: 5px;
                        padding-bottom: 5px;
                        background-color: $secondary-dark;
                        &:hover {
                            background-color: $gray-light;
                        }
                    }

                    &.selected {
                        font-weight: bold;
                    }
                }
            }
        }
    }
}
.__react_component_tooltip {
    z-index: 999999 !important;
}